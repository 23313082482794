import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { SERVICE_NAME } from 'src/constants'
import { START_PATH } from 'src/routes'

const LoginPage = () => {
  const router = useRouter()
  useEffect(() => {
    router.push(START_PATH)
  }, [])

  return (
    <>
      <Head>
        <title>ログイン | {SERVICE_NAME}</title>
      </Head>
    </>
  )
}

export default LoginPage
